<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="Contract__title + '_EUTR_Due_Diligence_Assessment_Letter'"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col>
                            <v-row no-gutters class="pt-1">
                                <v-col class="col-2-5">
                                    <img :src="appLogo" class="mb-0 mr-3" width="120px"/>
                                </v-col>
                                <v-col class="col-9-5 d-flex flex-column pt-0">
                                    <div>
                                        <span class="header-title">{{ Office__title  }}</span>
                                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(Boolean).join(', ')  }}</span>
                                    <div class="d-flex flex-row">
                                        <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null  && Office__contracttel != ''">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                        <span :class="'header-subtitle pt-1 pb-0' + (Office__contracttel != null && Office__contracttel != '' ? ' ml-10' : '')" v-if="Contract__office_id == 20">{{ getOfficeTaxId() + ' (Head Office)'}}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <v-col cols="12">{{ letterDate }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <v-col cols="12" class="d-flex flex-column">
                                    <span class="font-weight-bold">{{ Customer__title }}</span>
                                    <span>{{ Customer__address1 }}</span>
                                    <span>{{ Customer__address2 }}</span>
                                    <span>{{ Customer__address3 }}</span>
                                    <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '') }}</span>
                                    <span>{{ Customer__state }}</span>
                                    <span>{{ Customer__country }}</span>
                                    <span>{{ getTaxId() }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.eutrEudrDocuments.dueDiligenceAssessmentContract') + ' ' + Contract__title }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-6">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.eutrEudrDocuments.toWhom') }}</v-col>
                            </v-row>
                            <v-row no-gutters class="pt-8">
                                <v-col cols="12">
                                    <p>{{ $t('message.eutrEudrDocuments.confirmedExerciseDueDiligence') }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-4">
                                <v-col cols="12">
                                    <p>{{ $t('message.eutrEudrDocuments.pleaseFindAttached') }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="12" class="d-flex flex-column">
                                    <span>1)&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('message.eutrEudrDocuments.supplierRiskAssessment') }}</span>
                                    <span>2)&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('message.eutrEudrDocuments.selfDeclarationHarvesting') }}</span>
                                    <span>3)&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('message.eutrEudrDocuments.allSupporting') }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-6">
                                <v-col cols="12">
                                    <p>{{ $t('message.eutrEudrDocuments.dontHesitate') }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-6">
                                <v-col cols="12" class="d-flex flex-column">
                                    <span>{{ $t('message.eutrEudrDocuments.yoursFaithfully') }},</span>
                                    <span class="font-weight-bold">{{ Office__title }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-10">
                                <v-col cols="12" class="d-flex flex-column">
                                    <div class="signature-container">
                                        <img :src="Document__signature" width="120px"/>
                                        <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px"/>
                                    </div>
                                    <div class="signature-underline"></div>
                                    <span>{{ Document__signatory }}</span>
                                    <span>{{ Document__signatory_position }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import {api} from "Api";
import {log} from "Helpers/helpers"
import VueHtml2pdf from 'vue-html2pdf';
import {months} from '../../../store/modules/appic/constants'
import AppConfig from "Constants/AppConfig";
import {mapGetters} from "vuex";
import S3 from 'aws-s3-pro';

export default {
    name: "DueDiligenceAssessmentLetter",
    props: ['contractId'],
    components: {VueHtml2pdf},
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            Contract__office_id: 1,
            Contract__title: null,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__company_id: null,
            Customer__country: null,
            Customer__country_id: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__taxno_type: null,
            Customer__uscc: null,
            Document__signatory: null,
            Document__signatory_position: null,
            Document__signature: null,
            months: months,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__contracttel: null,
            Office__companyno: null,
            Office__country: null,
            Office__currency_id: 1,
            Office__contractemail: null,
            Office__gstno: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__taxno_type: null,
            Office__website: null,
            // offices: offices,
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            }
        }
    },
    computed: {
        ...mapGetters('user',{
            s3Keys : 's3Keys'
        }),
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                dirName: 'others',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        letterDate() {
            const date = new Date()
            const month = this.months.find(m => m.Month.id == date.getMonth() + 1)
            return month.Month.text + ' ' + date.getDate().toString() + ', ' + date.getFullYear().toString()
        },
        S3Client(){
            return new S3(this.config);
        },
    },
    methods: {
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let reader = new FileReader()
                let _this = this
                reader.readAsDataURL(pdf.output('blob'));
                reader.onloadend = function () {
                    const fileName = _this.Contract__title.replace(' ','_') + '_EUTR_Due_Diligence_Assessment_Letter'
                    const uploadObject = new File([pdf.output('blob')], fileName + '.pdf')
                    _this.S3Client
                        .uploadFile(uploadObject, fileName)
                        .then((response) => {
                            if (response.status == 204) {
                                // create asset document in DB
                                let document = {
                                    id: null,
                                    asset: 'shipping',
                                    asset_id: parseInt(_this.contractId),
                                    asset_title: 'EUTR Due Diligence Assessment Letter',
                                    doctype_id: 412,
                                    location: response.location,
                                    uploaded: Date.now(),
                                    customeraccess: 1,
                                    asset_type: 'pdf'
                                }
                                const promise = new Promise((resolve, reject) => {
                                    api
                                        .post('/documents', {
                                            document: document
                                        })
                                        .then((response) => {
                                            if (response.data.status == 'success') {
                                                resolve(response.data.data)
                                            } else {
                                                reject(response.data.status)
                                            }
                                        })
                                        .catch((err) => {
                                            reject(err)
                                        })
                                })

                                promise
                                    .then((newDocument) => {
                                        document['id'] = newDocument?.id
                                        // document['uploaded'] = newDocument?.uploaded
                                        const fileObject = {
                                            AssetDocument: document
                                        }
                                        _this.$emit('pdf-uploaded', fileObject)
                                    })
                                    .catch((e) => {
                                        _this.$toast.error(e,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.$emit('pdf-not-uploaded')
                                    })
                            }
                        })
                        .catch((e) => {
                            _this.$toast.error(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.$emit('pdf-not-uploaded')
                        })
                }
            })
        },
        getOfficeTaxId () {
            let taxId = ''
            if(this.Office__gstno != null && this.Office__taxno_type != null) taxId = this.Office__taxno_type + ': ' + this.Office__gstno
            if(this.Office__gstno != null && this.Office__taxno_type == null) taxId = this.Office__gstno
            return taxId
        },
        getTaxId () {
            let taxId = ''
            if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
            if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            return taxId
        },
        loadEutrDocumentById(contractId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/eutr-eudr/" + contractId + "/ddal")
                    .then(response => {
                        for(let key in response.data.data[0]){
                            this[key] = response.data.data[0][key]
                        }
                        resolve(contractId)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    },
    created() {
        this.loadEutrDocumentById(this.contractId)
            .then(() => {
                this.$refs.html2Pdf.generatePdf()
            })
            .catch((error) => {
                log(error)
                this.$emit('pdf-not-uploaded')
            })
    }
}
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.signature-underline {
    width: 150px;
    height: 1px;
    background-color: black;
}
.paper-a4 {
    height: 1065px;
    margin-left: 75px;
    margin-top: 35px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:10pt !important;
    font-size:10pt !important;
    padding:2px !important;
    background-color: #FFFFFF !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
.signature-container {
    position: relative;
    width: 100%;
    height: 90px;
}
.signature-container > img {
    position: absolute;
    top:0;
    left:0;
}
.signature-container > img:first-child {
    z-index: 2;
}
.signature-container > img:last-child {
    left: 20px !important;
}
</style>